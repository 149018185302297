$("select.selectpicker").change(function () {
  const value = $(this).children("option:selected").val().toLowerCase();
  const type = $(this).children("option:selected").data('type')
  const params = `?${type}=${value}`
  const serviceSearch = $(this).data('service-search-products')

  updateButtonLinkParams($('.product-block-dynamic__head__link'), params)
  updateButtonLinkParams($('#seeAllProductsMobileBtn'), params)

  const productsContainer = $('.product-block-dynamic__wrapper')

  $.ajax({
    url: serviceSearch + params,
    success: function (data) {
      if (data && data.result) {
        const products = (data.result.products && data.result.products.slice(0, 4)) || []
        const productsToBeRemoved = productsContainer.find('.col-6.col-lg-3')
        let totalProductsRemained = 0

        if (productsToBeRemoved && productsToBeRemoved.length) {
          productsToBeRemoved.each(function () {
            if (!$(this).data('is-selected')) {
              $(this).remove()
            } else {
              totalProductsRemained++
            }
          })
        }

        if (products && products.length) {
          const buildImageTag = (product) => {
            return product.image && product.image.link ? `<img src="${product.image.link}" alt="${product.image.alt || ''}"/>` : ''
          }

          products.forEach((item, index) => {
            // It's just necessary add the amount of products to complete the 4 visible
            if ((index + 1) > (4 - totalProductsRemained)) return

            productsContainer.append(`
              <div class="col-6 col-lg-3">
                <a href="${item.link}" class="product-block-dynamic__item">
                  <div class="img aspectRatio--1x1">
                    ${buildImageTag(item)}
                  </div>
                  <div>${item.name}</div>
                </a>
              </div>
            `)
          })
        }

        const totalProducts = (data.result.total || 0) + totalProductsRemained

        updateButtonVisibility($('.product-block-dynamic__head__link'), totalProducts)
        updateButtonVisibility($('#seeAllProductsMobileBtn'), totalProducts)
      }
    }
  })
});

const updateButtonLinkParams = (button, params) => {
  const currentHref = button.attr('href')
  const newHref = currentHref.replace(/produkter\?.*$/, `produkter${params}`)
  button.attr('href', newHref)
}

const updateButtonVisibility = (button, totalItems) => {
  if (totalItems > 4) {
    button.removeClass('hidden')
    return
  }

  if (!button.hasClass('hidden')) {
    button.addClass('hidden')
  }
}
